import { FetchData, PostQuery } from "@hooks/api/useApi";
import classNames from "classnames";
import { useFormik } from "formik";
import * as moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import UserIndex from "../../components/User/UserIndex";

const salutationOption = [
  {
    options: [
      { label: "Dr.", value: "Dr." },
      { label: "Mr.", value: "Mr." },
      { label: "Mrs.", value: "Mrs." },
      { label: "Ms.", value: "Ms." },
    ],
  },
];

function AddEntry() {
  const history = useHistory();
  const aRef = useRef(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedSubGroup, setSelectedSubGroup] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEventList, setSelectedEventList] = useState(null);
  const [selectedSalutation, setSelectedSalutation] = useState(null);
  const [optionRegion, setOptionRegion] = useState(null);
  const [optionEvent, setOptionEvent] = useState(null);
  const [entryDetails, setEntryDetails] = useState();
  const [activeTabVartical, setoggleTabVertical] = useState(1);
  const [passedStepsVertical, setPassedStepsVertical] = useState([1]);
  const location = useLocation();
  const entryId = location?.search?.slice(4);
  const action = location?.pathname;
  const [passedSteps, setPassedSteps] = useState([1]);
  const [activeTab, setactiveTab] = useState(
    Number(entryId) && action == "/add-entry" ? 2 : 1
  );
  const [videoLinkInput, setVideoLinkInput] = useState([]);
  const [eventList, setEventList] = useState(null);
  const [eventOutput, setEventOutput] = useState(null);
  const [groupList, setGroupList] = useState(null);
  const [subGroupList, setSubGroupList] = useState(null);
  const [catList, setCatList] = useState(null);
  const [photoInput, setPhotoInput] = useState([]);
  const [getPhotos, setGetPhotos] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [errorMess, setErrorMess] = useState("");
  const [optionSubGroup, setOptionSubGroup] = useState(null);
  const [optionCategory, setOptionCategory] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(null);

  //Api calls and actions ---------------------------------------

  const addActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setEntryDetails(data.data.responseData);
        toggleTab(activeTab + 1);
      }
    },
  };
  const addEntryDetailActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        history.replace("/");
      }
    },
  };
  const deleteActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        refetchEntryDetails();
        setDeleteModal(false);
      }
    },
  };

  const actionsUserDetail = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };

  const entryActions = {
    enabled: false,
    select: (data) => {
      const list = data.data;
      return list;
    },
    onSuccess: (data) => {
      if (data?.status) {
        if (
          data?.responseData?.entry_basic_detail?.form_status == "Submitted"
        ) {
          history.push({
            pathname: "/user-entry-details",
            search: `id=${data?.responseData?.entry_basic_detail?.entry_id}`,
          });
        } else {
          setEntryDetails(data?.responseData?.entry_basic_detail);
          detailsFormik.setValues({
            adddSummary: data?.responseData?.entry_profile?.summary,
            addConcept: data?.responseData?.entry_profile?.concept,
            addObjective: data?.responseData?.entry_profile?.objective,
            addAmplification: data?.responseData?.entry_profile?.execution,
            addScale: data?.responseData?.entry_profile?.result,
            video: data?.responseData?.entry_videos,
            photo: data?.responseData?.entry_photos,
          });
          setVideoLinkInput(
            data?.responseData?.entry_videos.map((item) => item.video_link)
          );
          setGetPhotos(data?.responseData?.entry_photos.map((item) => item));
        }
      } else {
        history.replace("/");
      }
    },
  };

  const selectRegionAction = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        obj["label"] = item.name;
        obj["value"] = item.id;
        optionList.push(obj);
        setOptionRegion(optionList);
      });
    },
  };

  const selectEventActions = {
    select: (data) => {
      // const list = data.data.responseData.event_list;
      // return list;
      const list = data.data.responseData.event_list.filter(
        (eachVal) => eachVal.event_status === "Published"
      );
      return list;
    },
  };

  const { data: selectRegion, isLoading: regionLoading } = FetchData(
    "all-region",
    `/Admin/get-all-region`,
    selectRegionAction
  );

  const {
    data: selectEvent,
    isLoading: eventLoading,
    refech: eventRefech,
  } = FetchData("all-events", "/Admin/get-all-event", selectEventActions);

  const { mutate: addEntry, isLoading: isLoadingPostEntry } = PostQuery(
    "/User/add-basic-entry",
    "post",
    addActions
  );
  const { mutate: deletePhoto, isLoading: isLoadingDeletePhoto } = PostQuery(
    "/User/delete-entry-photo",
    "post",
    deleteActions
  );

  const { mutate: addPhoto } = PostQuery("/User/insert-entry-photo", "post");
  const { mutate: addEntryEvent, isLoading: isLoadingAddEntry } = PostQuery(
    "/User/upsert-entry-profile",
    "post",
    addEntryDetailActions
  );
  const {
    data: entryData,
    isLoading: isEntryDetailsLoading,
    isFetching: isEntryDetailsFetching,
    refetch: refetchEntryDetails,
  } = FetchData(
    "entry-details",
    `User/get-user-entry-by-id?id=${entryId} `,
    entryActions
  );
  useEffect(() => {
    if (entryId) {
      refetchEntryDetails();
    }
  }, [entryId]);

  const hanldleEvents = (region) => {
    let output = selectEvent.filter((eachVal) => {
      let opt = eachVal.event_categorydata.some(
        ({ regionId }) => regionId === region
      );
      return opt;
    });
    setEventOutput(output);
    let optionEventList = [];
    output.map((item) => {
      let obj = {};
      obj["label"] = item.event_name;
      obj["value"] = item.event_id;
      optionEventList.push(obj);
      setEventList(optionEventList);
    });
  };

  const handleCategories = (event) => {
    let groupList = eventOutput.filter((eachVal) => {
      return eachVal.event_id === event.value;
    });

    let group = [];
    groupList[0]?.event_categorydata.map((item) => {
      let obj = {};
      obj["label"] = item.groupName;
      obj["value"] = item.groupId;
      group.push(obj);
      const arrayUniqueByKey = [
        ...new Map(group.map((item) => [item["label"], item])).values(),
      ];
      setGroupList(arrayUniqueByKey);
    });

    let subgroup = [];
    groupList[0]?.event_categorydata.map((item) => {
      let obj = {};
      obj["label"] = item.subGroupName;
      obj["value"] = item.subGroupId;
      subgroup.push(obj);
      const arrayUniqueByKeysubGroup = [
        ...new Map(subgroup.map((item) => [item["label"], item])).values(),
      ];
      setSubGroupList(arrayUniqueByKeysubGroup);
    });

    let categories = [];
    groupList[0]?.event_categorydata.map((item) => {
      let obj = {};
      obj["label"] = item.categoryName;
      obj["value"] = item.categoryId;
      categories.push(obj);
      const arrayUniqueByKeyCatList = [
        ...new Map(categories.map((item) => [item["label"], item])).values(),
      ];
      setCatList(arrayUniqueByKeyCatList);
    });
  };

  const [choosenEvent, setChoosenEvent] = useState(null);

  function handleSelectRegion(item) {
    setSelectedRegion(item);
    hanldleEvents(item.value);
    formik.setFieldValue("region", item.value);
  }

  const {
    data: userDetails,
    refetch,
    isFetching,
    isLoading,
  } = FetchData("userDetails", "User/get-user-profile", actionsUserDetail);

  const userName = userDetails?.general?.entrant_name;

  function handleSelectEventList(item) {
    setSelectedEventList(item);
    formik.setFieldValue("event", item.value);
    handleCategories(item);
    setChoosenEvent(
      eventOutput.filter((eachVal) => {
        return eachVal.event_id === item.value;
      })
    );
  }

  function handleSelectGroup(item) {
    setSelectedGroup(item);
    formik.setFieldValue("group", item.value);
    // if (selectedSubGroup) {
    setSelectedSubGroup(null);
    // }
  }

  function handleSelectSubGroup(item) {
    setSelectedSubGroup(item);
    formik.setFieldValue("subGroup", item.value);
  }

  function handleSelectCategory(item) {
    setSelectedCategory(item);
    formik.setFieldValue("category", item.value);
  }

  function handleSelectEvent(item) {
    setSelectedEvent(item);
    formik.setFieldValue("event", item.value);
  }

  function handleSelectSalutation(item) {
    setSelectedSalutation(item);
    formik.setFieldValue("salutation", item.value);
  }

  const handleCheckTerms = (e) => {
    if (e.target.checked) {
      formik.setFieldValue("IsClient_Approval_Require", false);
      formik.setFieldValue("salutation", "");
      formik.setFieldValue("clientName", "");
      formik.setFieldValue("designation", "");
      formik.setFieldValue("company", "");
      formik.setFieldValue("mobile", "");
      formik.setFieldValue("email", "");
      formik.setFieldValue("country", "");
      formik.setFieldValue("city", "");
    } else {
      formik.setFieldValue("IsClient_Approval_Require", true);
      formik.setFieldValue("Client_Approval_Des", "");
    }
  };

  const handleSameContact = (e) => {
    if (e.target.value === "yes") {
      formik.setFieldValue("isPrimarySame", true);
    } else {
      formik.setFieldValue("isPrimarySame", false);
    }
  };
  const handleOfficialAd = (e) => {
    if (e.target.value === "yes") {
      formik.setFieldValue("isOfficialAd", true);
    } else {
      formik.setFieldValue("isOfficialAd", false);
    }
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 2) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab];

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab);
        setPassedStepsVertical(modifiedSteps);
      }
    }
  }
  const getPhotLength = photoInput?.length + getPhotos?.length;
  const handleAddPhoto = () => {
    if (getPhotLength < 5) {
      setPhotoInput([...photoInput, null]);
    }
  };

  const handleChangePhoto = (e, i) => {
    const maxAllowedSize = 5 * 1024 * 1024;

    if (
      e.target.files[0].size > maxAllowedSize ||
      e.target.files[0].name.length > 125
    ) {
      if (e.target.files[0].name.length > 125) {
        e.target.value = "";
        setFileSizeError(true);
        setErrorMess("Your file name is too big");
        // setTimeout(() => {
        //   setErrorMess("");
        // }, 3000);
      } else {
        e.target.value = "";
        setFileSizeError(true);
        setErrorMess("Your file Size is too big");
        // setTimeout(() => {
        //   setErrorMess("");
        // }, 3000);
      }
      // Here you can ask your users to load correct file

      // detailsFormik.setErrors({ photo: "Your" });
    }
    photoInput[i] = e.target.files[0];
    detailsFormik.setFieldValue("photo", photoInput);
    setPhotoInput(photoInput);
    // setErrorMess("");
  };
  const handlePhotoDelete = (i) => {
    photoInput.splice(i, 1);
    setPhotoInput(photoInput);
    setErrorMess("");
    detailsFormik.setFieldValue("photo", photoInput);
  };

  const handleAddVideoLinks = () => {
    if (videoLinkInput.length < 2) {
      setVideoLinkInput([...videoLinkInput, ""]);
    }
  };

  const handleChangeVideoLinkInput = (e, i) => {
    videoLinkInput[i] = e.target.value;
    setVideoLinkInput(videoLinkInput);
    detailsFormik.setFieldValue("video", videoLinkInput);
  };
  const handleVideoLinkDelete = (i) => {
    videoLinkInput.splice(i, 1);
    setVideoLinkInput(videoLinkInput);
    detailsFormik.setFieldValue("video", videoLinkInput);
  };

  // Formik -------------------------------------------------

  const handlePostForm = () => {
    const data = {
      FormStatus: true,
      Entry_Id: entryDetails?.entry_id,
      Summary: detailsFormik?.values.adddSummary,
      Objective: detailsFormik?.values.addObjective,
      Concept: detailsFormik?.values.addConcept,
      Execution: detailsFormik?.values.addAmplification,
      Result: detailsFormik?.values.addScale,
      // UploadRefLinkList: refLinkArray,
      UploadVideoList: videoLinkArray,
    };
    addEntryEvent(data);

    photoInput &&
      photoInput.map((item) => {
        var formdata = new FormData();
        formdata.append("Photo", item, item.name);
        formdata.append("Entry_Id", entryDetails?.entry_id);
        addPhoto(formdata);
      });
  };

  const submitHandler = () => {
    const data = {
      FormStatus: false,
      Entry_Id: entryDetails?.entry_id,
      Summary: detailsFormik?.values.adddSummary,
      Objective: detailsFormik?.values.addObjective,
      Concept: detailsFormik?.values.addConcept,
      Execution: detailsFormik?.values.addAmplification,
      Result: detailsFormik?.values.addScale,
      // UploadRefLinkList: refLinkArray,
      UploadVideoList: videoLinkArray,
    };
    addEntryEvent(data);

    photoInput &&
      photoInput.map((item) => {
        var formdata = new FormData();
        formdata.append("Photo", item, item.name);
        formdata.append("Entry_Id", entryDetails?.entry_id);
        addPhoto(formdata);
      });
  };

  let createObj = {};
  const videoLinkArray = [];
  videoLinkInput.map((item) => {
    createObj = {
      Video_Name: item,
      Video: item,
    };

    videoLinkArray.push(createObj);
  });

  const onClickConfirm = () => {
    setConfirmModal(true);
  };
  const formik = useFormik({
    initialValues: {
      region: "",
      group: "",
      subGroup: "",
      category: "",
      eventEnd: [],
      eventStart: [],
      salutation: "",
      projectName: "",
      clientName: "",
      designation: "",
      company: "",
      mobile: "",
      email: "",
      country: "",
      city: "",
      event: "",
      IsClient_Approval_Require: true,
      Client_Approval_Des: "",
    },
    validationSchema: Yup.object({
      clientName: Yup.string().when("IsClient_Approval_Require", {
        is: true,
        then: Yup.string().required("Please Enter Your Name"),
      }),
      region: Yup.string().required("Please Select Region"),
      event: Yup.string().required("Please Select Event").nullable(),
      group: Yup.string().required("Please Select Group"),
      subGroup: Yup.string().required("Please Select Subgroup"),
      category: Yup.string().required("Please Select Category"),
      eventEnd: Yup.array().min(1, "Please Select Event end date"),
      eventStart: Yup.array().min(1, "Please Select Event Start date"),
      projectName: Yup.string().required("Enter Your Event Name"),
      designation: Yup.string().when("IsClient_Approval_Require", {
        is: true,
        then: Yup.string().required("Please Enter Designation"),
      }),
      email: Yup.string().when("IsClient_Approval_Require", {
        is: true,
        then: Yup.string().required("Please Enter Your Email"),
      }),
      mobile: Yup.string().when("IsClient_Approval_Require", {
        is: true,
        then: Yup.string().required("Please Enter Your Mobile Number"),
      }),
      country: Yup.string().when("IsClient_Approval_Require", {
        is: true,
        then: Yup.string().required("Please Enter Country"),
      }),
      city: Yup.string().when("IsClient_Approval_Require", {
        is: true,
        then: Yup.string().required("Please Enter City"),
      }),
      company: Yup.string().when("IsClient_Approval_Require", {
        is: true,
        then: Yup.string().required("Please Enter Company Name"),
      }),
      salutation: Yup.string().when("IsClient_Approval_Require", {
        is: true,
        then: Yup.string().required("Please Select Salutation"),
      }),
      Client_Approval_Des: Yup.string().when("IsClient_Approval_Require", {
        is: false,
        then: Yup.string().required("Please Field This"),
      }),
    }),
    // validate: (values) => {
    //   const errors = {};
    //   if (values.event === {}) {
    //     errors.event = 'Required';
    //   }
    //   return errors;
    // },

    onSubmit: (values) => {
      var formdata = new FormData();
      formdata.append("Region_id", values.region);
      formdata.append("Event_Id", values.event);
      formdata.append("Group_Id", values.group);
      formdata.append("Subgroup_Id", values.subGroup);
      formdata.append("Category_Id", values.category);
      formdata.append("Event_Name", selectedEventList.label);
      formdata.append("Salutation", values.salutation);
      formdata.append("Client_Name", values.clientName);
      formdata.append("Designation", values.designation);
      formdata.append("Company", values.company);
      formdata.append("Email", values.email);
      formdata.append("Direct_Number", values.mobile);
      formdata.append("Country", values.country);
      formdata.append("City", values.city);
      formdata.append("projectName", values.projectName);
      formdata.append(
        "IsClient_Approval_Require",
        JSON.parse(values.IsClient_Approval_Require)
      );
      formdata.append("vcNoApproval_Reason", values.Client_Approval_Des);
      formdata.append("Entry_Name", values.projectName);
      // formdata.append("Entrant_Name", userName);
      // formdata.append("Client_Mail", values.email);
      formdata.append(
        "Campaign_Start_Date",
        moment(values.eventStart[0]).format("YYYY-MM-DD")
      );
      formdata.append(
        "Campaign_End_Date",
        moment(values.eventEnd[0]).format("YYYY-MM-DD")
      );
      addEntry(formdata);
    },
  });

  const detailsFormik = useFormik({
    initialValues: {
      adddSummary: "",
      addObjective: "",
      addConcept: "",
      addScale: "",
      addAmplification: "",
      video: [],
      photo: [],
      FormStatus: null,
    },
    validationSchema: Yup.object({
      adddSummary: Yup.string()
        .required("Please Enter Summary")
        .max(1200, "Summary cannot exceed 1200 characters"), // Max characters for adddSummary
      addObjective: Yup.string()
        .required("Please Enter Objective")
        .max(1200, "Objective cannot exceed 1200 characters"), // Max characters for addObjective
      addConcept: Yup.string()
        .required("Please Enter Concept")
        .max(1200, "Concept cannot exceed 1200 characters"), // Max characters for addConcept
      addAmplification: Yup.string()
        .required("Please Enter Amplification")
        .max(2400, "Amplification cannot exceed 2400 characters"), // Max characters for addAmplification
      addScale: Yup.string()
        .required("Please Enter Result")
        .max(1800, "Result cannot exceed 1800 characters"),
      photo: Yup.array().min(1, "Please add atleast 1 file").nullable(),
      video: Yup.array().min(1, "Please add atleast 1 link"),
    }),

    onSubmit: (values) => {
      onClickConfirm();
    },
  });

  const onClickDelete = (data) => {
    setDeleteModal(true);
    setModalData(data);
  };

  const handleConfirmEntry = () => {
    handlePostForm();
  };
  const handleClose = () => {
    setFileSizeError(false);
  };

  const handleDeleteGroup = () => {
    let data = {
      photo_id: modalData.id,
    };
    deletePhoto(data);
  };

  const subGroupActions = {
    enabled: false,
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.sub_group_name;
          obj["value"] = item.sub_group_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionSubGroup(getList);
        }
      });
    },
  };

  const { data: subGroupData, refetch: fetchSubGroup } = FetchData(
    "group",
    `Admin/get-subgroup-of-group-by-id?groupid=${selectedGroup?.value}`,
    subGroupActions
  );

  const categoryActions = {
    enabled: false,
    select: (data) => {
      const list = data.data.responseData?.reverse();
      return list;
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Publish") {
          obj["label"] = item.category_name;
          obj["value"] = item.category_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionCategory(optionList);
        }
      });
    },
  };

  const { data: categoryData, refetch: fetchCategory } = FetchData(
    "group",
    `Admin/get-category-of-subgroup-by-id?subgroupid=${selectedSubGroup?.value}`,
    categoryActions
  );

  useEffect(() => {
    if (selectedGroup) {
      fetchSubGroup();
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedSubGroup) {
      fetchCategory();
    }
  }, [selectedSubGroup]);

  // useEffect(() => {
  //   const checkEventDate =
  //     formik?.values?.eventStart?.length > 0 &&
  //     moment(formik?.values?.eventStart[0]).format("DD/MM/YYYY") ===
  //       moment(formik?.values?.eventStart[1]).format("DD/MM/YYYY");

  //   if (checkEventDate && formik?.values?.eventStart?.length > 0) {
  //     formik.setFieldValue("eventStart", []);
  //     formik.setFieldError("eventStart", "Select start or end date");
  //   }
  // }, [formik?.values?.eventStart]);

  return (
    <React.Fragment>
      <UserIndex
        entryDetails={entryData?.responseData}
        action={action}
        confirmModal={confirmModal}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        classNames={classNames}
        activeTab={activeTab}
        setactiveTab={setactiveTab}
        passedSteps={passedSteps}
        handleSelectRegion={handleSelectRegion}
        optionRegion={optionRegion}
        formik={formik}
        handleSelectEventList={handleSelectEventList}
        eventList={eventList}
        handleSelectGroup={handleSelectGroup}
        groupList={groupList}
        handleSelectSubGroup={handleSelectSubGroup}
        subGroupList={subGroupList}
        catList={catList}
        choosenEvent={choosenEvent}
        handleSelectSalutation={handleSelectSalutation}
        salutationOption={salutationOption}
        handleCheckTerms={handleCheckTerms}
        detailsFormik={detailsFormik}
        handleAddPhoto={handleAddPhoto}
        getPhotos={getPhotos}
        entryId={entryId}
        onClickDelete={onClickDelete}
        handlePhotoDelete={handlePhotoDelete}
        optionSubGroup={optionSubGroup}
        optionCategory={optionCategory}
        photoInput={photoInput}
        errorMess={errorMess}
        handleAddVideoLinks={handleAddVideoLinks}
        handleChangeVideoLinkInput={handleChangeVideoLinkInput}
        handleVideoLinkDelete={handleVideoLinkDelete}
        submitHandler={submitHandler}
        isEntryDetailsLoading={isEntryDetailsLoading}
        isEntryDetailsFetching={isEntryDetailsFetching}
        eventLoading={eventLoading}
        videoLinkInput={videoLinkInput}
        regionLoading={regionLoading}
        isLoadingPostEntry={isLoadingPostEntry}
        isLoadingAddEntry={isLoadingAddEntry}
        handleSelectCategory={handleSelectCategory}
        handleDeleteGroup={handleDeleteGroup}
        handleConfirmEntry={handleConfirmEntry}
        toggleTab={toggleTab}
        handleChangePhoto={handleChangePhoto}
        handleSelectEvent={handleSelectEvent}
        getPhotLength={getPhotLength}
        setConfirmModal={setConfirmModal}
        fileSizeError={fileSizeError}
        setFileSizeError={setFileSizeError}
        handleClose={handleClose}
      />
    </React.Fragment>
  );
}
export default AddEntry;
