import TableContainer from "@components/Common/TableContainer";
import { FetchData, PostQuery } from "@hooks/api/useApi";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import MarkSubmtting from "../../../components/Common/MarkNotSubmitting";
import ScreenLoader from "../../../components/Common/ScreenLoader";
import { ID } from "../EditableCol";

function Index() {
  let paymentCode;

  document.title = "Dashboard | WOW Awards";
  const [activeTab, setactiveTab] = useState(1);
  const [activeTabName, setactiveTabName] = useState();
  const [regions, setRegions] = useState([]);
  const history = useHistory();

  const actionsEntries = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      if (data?.length > 0) {
        setRegions([...new Set(data?.map((item) => item.region))]);
        setactiveTabName([...new Set(data?.map((item) => item.region))][0]);
      }
    },
  };

  const {
    data: entriesList,
    isFetching,
    isLoading,
    refetch,
    isFetched,
  } = FetchData("entries", "User/get-all-user-entry", actionsEntries);

  const submittingActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setDeleteModal(false);
        refetch();
      } else {
        // setApproveEmailError(data.data.errors[0]);
      }
    },
    onError: (error) => {
      // setApproveEmailError('Something went wrong!');
    },
  };
  const { mutate } = PostQuery(
    "User/mark-entry-not-submitting",
    "post",
    submittingActions
  );

  const submitCodeActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        refetch();
      } else {
        // setApproveEmailError(data.data.errors[0]);
      }
    },
    onError: (error) => {
      // setApproveEmailError('Something went wrong!');
    },
  };
  const { mutate: submitCode, isLoading: paymentLoading } = PostQuery(
    "User/add-and-mail-paymentcode-for-entry",
    "post",
    submitCodeActions
  );

  const [errorMess, setErrorMess] = useState(false);
  const onHandlePayment = (id, code) => {
    if (code !== undefined) {
      const data = {
        Payment_Code: code,
        Entry_Id: id,
      };
      submitCode(data);
    } else {
      setErrorMess(true);
    }
  };

  const duplicateAction = {
    onSuccess: (data) => {
      if (data.data.status) {
        history.push({
          pathname: "/duplicate-entry",
          search: `id=${data?.data?.responseData}`,
        });
      }
    },
  };

  const { mutate: duplicateEntry, isLoading: isDuplicateLoading } = PostQuery(
    "/User/create-duplicate-entry",
    "post",
    duplicateAction
  );

  const handleDuplicateEntry = (id) => {
    const duplicateData = {
      ExistingEntryId: id,
      EntryIdForUpdation: null,
    };
    duplicateEntry(duplicateData);
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },
      {
        Header: "Entry Code",
        accessor: "entry_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },

      {
        Header: "Entry Name",
        accessor: "entry_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p
              style={{
                color: `${
                  cellProps.row.original.is_entry_rejected ? "red" : "#CC962B"
                }`,
                cursor: "pointer",
              }}
              onClick={() => {
                cellProps?.row?.original?.form_status === "Submitted" ||
                cellProps?.row?.original?.is_entry_rejected
                  ? history.push({
                      pathname: "/user-entry-details",
                      search: `id=${cellProps?.row?.original?.entry_id}`,
                    })
                  : history.push({
                      pathname: "/add-entry",
                      search: `id=${cellProps?.row?.original?.entry_id}`,
                    });
              }}
            >
              {cellProps.value}
            </p>
          );
        },
      },

      {
        Header: "Entry Group",
        accessor: "group_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Entry Subgroup",
        accessor: "subgroup_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Entry Category",
        accessor: "category_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Entrant name",
        accessor: "entrant_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },

      {
        Header: "Form Status",
        accessor: "form_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-12 badge-soft-" +
                (cellProps.row.original.is_entry_rejected
                  ? "danger"
                  : cellProps.value === "Submitted"
                  ? "success"
                  : "warning")
              }
              pill
            >
              {cellProps.row.original.is_entry_rejected
                ? "Entry Rejected"
                : cellProps.value === "Submitted"
                ? "Submitted"
                : "Incomplete"}
            </Badge>
          );
        },
      },
      {
        Header: "Client Approval Status",
        accessor: "client_approval_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p style={{ fontWeight: "700" }}>
              {cellProps.row.original.client_approval_status.replace(/_/, " ")}
            </p>
          );
        },
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.row.original.payment_status == "Pending" &&
            cellProps.row.original.payment_code == "" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                minHeight: 160,
                width: 130,
              }}
            >
              <a
                href="https://rzp.io/rzp/b2swfi0"
                target="_blank"
                style={{
                  backgroundColor: "#CC962B",
                  padding: "6px",
                  marginBottom: "10px",
                  color: "white",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              >
                {" "}
                INR Pay
              </a>
              <a
                href="https://rzp.io/rzp/GpWNctFi"
                target="_blank"
                style={{
                  backgroundColor: "#CC962B",
                  padding: "6px",
                  marginBottom: "10px",
                  color: "white",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              >
                {" "}
                International Pay
              </a>
            </div>
          ) : (
            <p style={{ fontWeight: "700" }}>
              {cellProps.row.original.payment_status}
            </p>
          );
        },
      },
      {
        Header: "Payment Code",
        accessor: "payment_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.row.original.payment_status == "Pending" &&
            cellProps.row.original.payment_code == "" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: 160,
                width: 130,
              }}
            >
              <Input
                name="code"
                type="text"
                style={{ marginBottom: "10px" }}
                onChange={(e) => {
                  // setPaymentCode(e.target.value);
                  paymentCode = e.target.value;
                }}
              />
              {errorMess && (
                <span style={{ color: "red", fontSize: "10px" }}>
                  Enter Code
                </span>
              )}
              <Button
                type="submit"
                color="primary"
                onClick={() =>
                  onHandlePayment(
                    cellProps.cell.row.original.entry_id,
                    paymentCode
                  )
                }
              >
                Submit Razerpay Code
              </Button>
            </div>
          ) : (
            <p style={{ fontWeight: "700" }}>
              {cellProps.row.original.payment_code}
            </p>
          );
        },
      },
      {
        Header: "Mark as Not Submitting",
        accessor: "notSubmitting",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Button
              className="btn-sm"
              disabled={
                cellProps.row.original.is_entry_rejected ||
                cellProps.row.original.not_submitting_status ===
                  "Not Submitting" ||
                cellProps.row.original.final_status === "Completed"
                  ? true
                  : false
              }
              onClick={() =>
                onClickDelete(cellProps.cell.row.original.entry_id)
              }
            >
              {cellProps.row.original.not_submitting_status === "Submitting"
                ? "Mark not submitting"
                : "Not Submitting"}
            </Button>
          );
        },
      },
      {
        Header: "Final Status",
        accessor: "final_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p style={{ fontWeight: "700" }}>
              {cellProps.row.original.final_status}
            </p>
          );
        },
      },
      {
        Header: "Action",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: 160,
                width: 130,
              }}
            >
              <Button
                type="button"
                color="primary"
                onClick={() =>
                  handleDuplicateEntry(cellProps?.row?.original?.entry_id)
                }
                // disabled
              >
                Duplicate Entry
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );
  const [modalData, setModalData] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const onClickDelete = (order) => {
    setModalData(order);
    setDeleteModal(true);
  };
  const handleDeleteGroup = () => {
    const submitting = {
      Entry_Id: modalData,
    };
    mutate(submitting);
  };

  return (
    <React.Fragment>
      <MarkSubmtting
        show={deleteModal}
        onClick={handleDeleteGroup}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="d-flex align-items-center mb-2">
                      <div className="avatar-xs me-3 ">
                        <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-22">
                          <i className="bx bx-globe-alt text-primary display-7" />
                        </span>
                      </div>
                      <h3 className="font-size-25 mb-0">Create new Entry </h3>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        color="primary"
                        style={{ marginRight: 3 }}
                        onClick={() => history.push("/add-entry")}
                      >
                        Add Entry
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
              {entriesList?.length > 0 ? (
                <Card>
                  <CardBody>
                    <div
                      className="mb-3"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4 style={{ color: "#495057" }} className="mb-0">
                        Entries for {activeTabName}
                      </h4>
                      {regions.length > 1 ? (
                        <Nav
                          pills
                          className="icon-tab nav-justified"
                          role="tablist"
                          style={{ width: "45%" }}
                        >
                          {regions?.map((item, i) => {
                            return (
                              <NavItem key={i}>
                                <NavLink
                                  className={classNames({
                                    active: activeTab === i + 1,
                                  })}
                                  onClick={() => {
                                    setactiveTab(i + 1);
                                    setactiveTabName(item);
                                  }}
                                >
                                  {`${item} (${
                                    entriesList?.filter(
                                      (cat) => cat?.region === item
                                    )?.length
                                  })`}
                                </NavLink>
                              </NavItem>
                            );
                          })}
                        </Nav>
                      ) : null}
                    </div>

                    {entriesList ? (
                      <div>
                        <TableContainer
                          columns={columns}
                          data={entriesList?.filter(
                            (item) => item.region === activeTabName
                          )}
                          isGlobalFilter={true}
                          isAddOptions={false}
                          customPageSize={10}
                        />
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
              ) : (
                isFetched &&
                entriesList === "" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h4 className="text-primary">No Entries Found</h4>
                  </div>
                )
              )}
            </Col>
          </Row>
        </div>
        {(paymentLoading || isDuplicateLoading) && <ScreenLoader />}
      </div>
    </React.Fragment>
  );
}

export default Index;
