import React from "react";
import { FormFeedback, Input, Label } from "reactstrap";

export const InputField = (props) => {
  return (
    <>
      <Label className="form-label" htmlFor={props.name} style={props.style}>
        {props.label}
      </Label>
      <Input
        id={props.id}
        name={props.name}
        type={props.type}
        onChange={props.onChange}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        rows={props.rows}
        value={props.value || ""}
        invalid={props.invalid}
        disabled={props.disabled}
        style={props.style}
        maxLength={props?.maxLength}
      />
      {props.validation &&
      props.validation.touched[props.name] &&
      props.validation.errors[props.name] ? (
        <FormFeedback type="invalid">
          {props.validation.errors[props.name]}
        </FormFeedback>
      ) : null}
    </>
  );
};
